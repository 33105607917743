import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

import '@egjs/react-flicking/dist/flicking.css';
// Or, if you have to support IE9
import { Arrow } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/arrow.css';
import '@egjs/react-flicking/dist/flicking-inline.css';
import { useMemo } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImg } from '../../../utils/image-utils';

const initialCropValue = {
  unit: 'px',
  x: 50,
  y: 50,
  width: 512,
  height: 512,
};

const ManipulateImages = (props) => {
  const { files, setFiles } = props;
  const imageRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [crop, setCrop] = useState(initialCropValue);
  const [selectedImage, setSelectedImage] = useState(files[0]);
  const [originalBlobFile, setOriginalBlobFile] = useState(files[0].blobFile);

  //Calculate Crop Size
  useEffect(() => {
    const handleImageLoad = () => {
      const { offsetWidth, offsetHeight } = imageRef.current;
      const minSide = Math.min(offsetWidth, offsetHeight);      
    
      // Update initialCropValue with new width and height
      setCrop(prevCrop => ({
        ...prevCrop,
        width: minSide,
        height: minSide
      }));
    };
  
    if (imageRef.current) {
      imageRef.current.onload = handleImageLoad;
    }
  
    return () => {
      if (imageRef.current) {
        imageRef.current.onload = null;
      }
    };
  }, [selectedImage?.id, files]);

  /**
   * This function reads the content of a blob file and returns a data source using the FileReader API
   * in JavaScript.
   */
  const readBlobContentAndReturnDataSrc = (blobFile) => {
    setImgSrc(URL.createObjectURL(blobFile));
    setIsCropping(false);
  };

  useEffect(() => {
    if (selectedImage?.blobFile) {
      setIsCropping(true);
      readBlobContentAndReturnDataSrc(selectedImage?.blobFile);
    }
  }, [selectedImage?.id]);

  const findAndReplaceCroppedImage = (selectedImage, croppedImageBlob) => {
    const originalFile = [...files];
    let foundCroppedIndex = originalFile.findIndex(
      (file) => file.id === selectedImage.id
    );
    originalFile[foundCroppedIndex] = {
      ...originalFile[foundCroppedIndex],
      blobFile: croppedImageBlob,
    };
    setFiles(originalFile);
    setCrop(initialCropValue);
  };

  const saveCropFileHandler = async () => {
    if (imageRef?.current && crop?.width && crop?.height && !isCropping) {
      const croppedImageBlob = await getCroppedImg(
        imageRef.current,
        crop,
        selectedImage,
        {
          onCropping: () => setIsCropping(true),
        }
      );
      setSelectedImage({
        ...selectedImage,
        blobFile: croppedImageBlob,
      });
      readBlobContentAndReturnDataSrc(croppedImageBlob);
      findAndReplaceCroppedImage(selectedImage, croppedImageBlob);
    }
  };

  const revertSelectedCropFile = () => {
    setSelectedImage({
      ...selectedImage,
      blobFile: originalBlobFile,
    });
    readBlobContentAndReturnDataSrc(originalBlobFile);
    findAndReplaceCroppedImage(selectedImage, originalBlobFile);
  };


  const filesListWithBlobDataSrc = useMemo(() => {
    const fileWithBlob = [];
    files?.forEach(async (file) => {
      fileWithBlob.push({
        ...file,
        imgSrc: URL.createObjectURL(file.blobFile),
      });
    });
    return fileWithBlob;
  }, [files]);

  return (
    <div>
      <div className='crop-image-wrapper'>
        {selectedImage.blobFile && imgSrc && (
          <>
            <div className='rcrop-wrapper'>
              <ReactCrop
                onComplete={(c) => setCrop(c)}
                crop={isCropping ? null : crop}
                onChange={(c) => setCrop(c)}
                aspect={1}
                locked={true}
              >
                <img
                  ref={imageRef}
                  className={`crop-image ${isCropping ? 'isCropping' : ''}`}
                  src={imgSrc}
                  alt='crop'
                />

                {isCropping && <div className='croppingLayer'>&nbsp;</div>}

                {isCropping && <div className='spinner mt-0 ml--24'></div>}
              </ReactCrop>
            </div>
            <div className='crop-image-button'>
              <button
                disabled={isCropping}
                className='btn btn-success mr-4'
                type='button'
                onClick={saveCropFileHandler}
              >
                {props.selectedLanguage.translation.cropImageButton}
              </button>
              <button
                disabled={isCropping}
                className='btn btn-secondary'
                type='button'
                onClick={revertSelectedCropFile}
              >
                {props.selectedLanguage.translation.cancleButton}
              </button>
            </div>
          </>
        )}
      </div>

      <div id='carousel-body'>
        <Flicking
          renderOnSameKey
          align={filesListWithBlobDataSrc?.length > 4 ? 'prev' : 'center'}
          plugins={[
            new Arrow({ parentEl: document.getElementById('carousel-body') }),
          ]}
        >
          {filesListWithBlobDataSrc
            ?.sort((prevFile, nextFile) => +prevFile.id - +nextFile.id)
            ?.map((file) => {
              const { blobFile, id, imgSrc } = file;
              return (
                <div
                  onClick={() => {
                    setSelectedImage(file);
                    setOriginalBlobFile(blobFile);
                    setCrop(initialCropValue);
                  }}
                  className={`carousel-item ${id === selectedImage.id ? 'isSelected' : ''
                    }`}
                  key={`${id}`}
                >
                  <img className='carousel-image ' src={imgSrc} alt='img' />
                  {/* <span className='filename'>{blobFile.name}</span>*/}
                </div>
              );
            })}
          <ViewportSlot>
            <span className='flicking-arrow-prev is-circle'></span>
            <span className='flicking-arrow-next is-circle'></span>
          </ViewportSlot>
        </Flicking>
      </div>
    </div>
  );
};

export default ManipulateImages;