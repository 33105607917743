import axios from 'axios';
import imageCompression from 'browser-image-compression';
import JSZip from 'jszip';
import React, { useState, useEffect } from 'react';
import './App.css';
import Modal from './components/Modal/Modal';
import './core/ImageFunctionality/ImageFunctionality.css';
import ManipulateImages from './core/ImageFunctionality/ManipulateImages/ManipulateImages';
import ImageFunctionality from './core/ImageFunctionality/ViewSelectedImage/ViewSelectedImage';
import ReactHtmlParser from 'html-react-parser';
import Footer from './components/footer/footer.js';
import WarningPopup from './components/WarningPopup/WarningPopup';
import PictureAnalyzer from './utils/PictureAnalyzer';
import ImageCheckContent from './core/ImageCheckFuncionality/CheckImages.jsx'
import imageSizeCheck from './utils/imageSizeCheck.js'

//Import languages
import en from './locales/en.json';
import de from './locales/de.json';
import fr from './locales/fr.json';
import it from './locales/it.json';

const availableLanguages = [
  { code: 'de', name: 'Deutsch', translation: de },
  { code: 'en', name: 'English', translation: en },
  { code: 'fr', name: 'Français', translation: fr },
  { code: 'it', name: 'Italiano', translation: it },
  // { code: 'es', name: 'Español', translation: es },
  // Add any other languages you support
];

const MAX_IMAGES = 15;
const MIN_IMAGES = 10;
let fileIndex = 0;

function UploadForm() {
  const [files, setFiles] = useState([]);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add state for loading indicator
  const [error, setError] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(availableLanguages[0]);
  const [viewImageManipulationModal, setViewImageManipulationModal] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(''); // State variable for the selected product
  const [selectedBackground, setSelectedBackground] = useState('grey background');
  const [selectedStyle, setSelectedStyle] = useState('');
  const [selectedColour, setSelectedColour] = useState('navy blue');
  const [selectedGender, setSelectedGender] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setSelectedStyle(selectedGender === 'woman' ? 'blazer' : 'suit');
  }, [selectedGender]);

  const toggleImageManipulationModal = () => {
    setViewImageManipulationModal(!viewImageManipulationModal);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  //set language
  const handleLanguageChange = (event) => {
    const selectedLanguageCode = event.target.value;
    const selectedLanguage = availableLanguages.find(language => language.code === selectedLanguageCode);
    setSelectedLanguage(selectedLanguage);
  };

  //set product
  const handleSelectedOption = (event) => {
    const selectedOption = event.target.value;

    if (!selectedGender) {
      // Show the warning popup if gender is not selected
      setShowWarning(true);
      setSelectedOption(''); // Reset the selected option
      return;
    }

    setSelectedOption(selectedOption);
    console.log("Selected option in handler", selectedOption)
  };

  // Handler function for selecting the "Style" option
  const handleSelectedStyle = (event) => {
    const selectedStyle = event.target.value;
    setSelectedStyle(selectedStyle);
  };

  // Handler function for selecting the "Background" option
  const handleSelectedBackground = (event) => {
    const selectedBackground = event.target.value;
    setSelectedBackground(selectedBackground);
  };

  // Handler function for selecting the "Background" option
  const handleSelectedColour = (event) => {
    const selectedColour = event.target.value;
    setSelectedColour(selectedColour);
  };

  const handleSelectedGender = (event) => {
    const selectedGender = event.target.value;
    setSelectedGender(selectedGender);
  };

  const parsedAGB = ReactHtmlParser(selectedLanguage.translation.terms.agreementText)

  async function handleFileUpload(event) {
    let selectedFiles = Array.from(event.target.files).map((file) => ({
      id: `${fileIndex++}`,
      blobFile: file,
    }));

    try {
      const { validImages, failedImages } = await imageSizeCheck(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...validImages]);

      if (failedImages.length > 0) {
        // Show the User which files did not meet the criteria
        const errorMessage = failedImages.join(', ');
        setError(selectedLanguage.translation.errorMessages.dimension + errorMessage);
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error filtering images:", error);
    }
  };


  const fetchData = async (selectedOption) => {
    try {
      console.log("This is the product which creates the session", selectedOption)
      const res = await fetch(
        'https://dev23.formalframe.com/create-checkout-session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify({
            items: [{ id: 1, quantity: 1, product: selectedOption }],
          }),
        }
      );

      console.log('CORS:', res.headers.get('Access-Control-Allow-Origin'));

      if (res.ok) {
        const json = await res.json();
        console.log(json);
        //window.location = json.url;
        console.log(json.sessionId);
        return { sessionId: json.sessionId, url: json.url }; // Return the session.id value
      } else {
        const json = await res.json();
        console.error(json.error);
        return null; // Return null if there is an error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading state to true on form submit
    const gender = event.target.elements.gender.value;

    const zip = new JSZip();

    if (files.length < MIN_IMAGES) {
      // Check if at least 10 pictures are selected
      setError(`Please select at least ${MIN_IMAGES} pictures.`);
      setIsLoading(false);
      return;
    }

    // Compress the images before adding them to the zip file
    const compressedImages = await Promise.all(
      files.map(async (file) => {
        const options = {
          maxSizeMB: 3.5, // maximum size of the compressed image in MB
          useWebWorker: true, // use a web worker to offload the compression process
        };
        const compressedFile = await imageCompression(file.blobFile, options);
        return compressedFile;
      })
    );

    compressedImages.forEach((file, index) => {
      zip.file(
        `image-${index}${file.name.substr(file.name.lastIndexOf('.'))}`,
        file,
        { base64: true }
      );
    });

    const blob = await zip.generateAsync({ type: 'blob' });

    console.log('Size of the resulting zip file: ' + blob.size + ' bytes');

    const formData = new FormData(); // Append all Data to Form
    formData.append('zip', blob, 'images.zip');
    formData.append('email', email);
    formData.append('gender', gender);
    formData.append('language', selectedLanguage.code);
    const { sessionId, url } = await fetchData(selectedOption);
    console.log('this is the Id which goes to Backend', sessionId);
    formData.append('sessionId', sessionId);
    formData.append('product', selectedOption);
    formData.append('style', selectedStyle);
    formData.append('background', selectedBackground);
    formData.append('colour', selectedColour);


    if (sessionId) {
      try {
        const response = await axios.post(
          'https://dev23.formalframe.com/upload-zip',
          formData
        );
        console.log(response.data);
        window.location = url;
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('fetchData failed to complete');
    }
  };

  return (
    <>
      <div className='banner'>
        <div className='headerContainer'>
          <div className='bannerText'>FORMALFRAME</div>
          <div className='additionalText'>Digital Presence for Real People</div>
        </div>
        <select value={selectedLanguage.code} onChange={handleLanguageChange}>
          {availableLanguages.map(language => (
            <option key={language.code} value={language.code}>{language.name}</option>
          ))}
        </select>
      </div>
      <div className='container'>
        <form onSubmit={handleSubmit} className='formContainer' disabled={isLoading}>
          <ImageFunctionality files={files} />
          <Modal
            title={selectedLanguage.translation.cropImagesButton}
            onClose={toggleImageManipulationModal}
            show={viewImageManipulationModal}
            selectedLanguage={selectedLanguage}
          >
            <ManipulateImages files={files} setFiles={setFiles} selectedLanguage={selectedLanguage} />
          </Modal>

          {files?.length > 0 && (
            <div className='text-center w-100'>
              <button
                className='standard-button'
                onClick={toggleImageManipulationModal}
                type='button'
                disabled={isLoading}
              >
                {selectedLanguage.translation.cropImagesButton}
              </button>
            </div>
          )}
          <div className='image-container'>
            <img src={require('./media/guide.png')} alt='Guide' />
          </div>
          <div className='textContainer'>
            <div>
              {selectedLanguage.translation.guideTexts.map((text, index) => (
                <p key={index} style={{ fontWeight: 'normal' }}>
                  {text}
                </p>
              ))}
            </div>
          </div>
          {files.length < MAX_IMAGES && (
            <input
              type='file'
              accept='image/*'
              onChange={handleFileUpload}
              multiple
              className='fileInput'
            />
          )}
          <label htmlFor='email' className='emailLabel'>
            Email:
          </label>
          <input
            id='email'
            type='email'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
            className='emailInput'
            disabled={isLoading}
          />
          <br />
          <div>
            <label>
              <input type='radio' name='gender' value='man' required onChange={handleSelectedGender} />
              {selectedLanguage.translation.genderOptions.man}
            </label>
            <label>
              <input type='radio' name='gender' value='woman' required onChange={handleSelectedGender} />
              {selectedLanguage.translation.genderOptions.woman}
            </label>
            <label>
              <input type='radio' name='gender' value='person' required onChange={handleSelectedGender} />
              {selectedLanguage.translation.genderOptions.person}
            </label>
          </div>
          <br />
          <div className='AGBsContainer'>
            <label>
              <input type='checkbox' required />
              {parsedAGB}
            </label>
          </div>
          <br />
          {/* Add WarningPopup component */}
          {showWarning && (
            <WarningPopup
              title="Ups.. there was an error"
              popupcontent={selectedLanguage.translation.popupcontent}
              onClose={() => setShowWarning(false)}
            />
          )}
          <div className='pricesContainer'>
            <div className='priceBox'>
              <h2>Basic Package 💸</h2>
              <div className='priceBoxList'>
                <ul>
                  <li>{selectedLanguage.translation.prices.basicPackageFeatures[0]}</li>
                  <li>{selectedLanguage.translation.prices.basicPackageFeatures[1]}</li>
                  <li>{selectedLanguage.translation.prices.basicPackageFeatures[2]}</li>
                  <li>{selectedLanguage.translation.prices.basicPackageFeatures[3]}</li>
                </ul>
              </div>
              <div>
                <label className={`buyButton ${selectedOption === 'product1' ? 'blackBackground' : ''}`}>
                  <input
                    type="radio"
                    name="product"
                    value="product1"
                    checked={selectedOption === 'product1'}
                    onChange={handleSelectedOption}
                    required
                  />
                  Select (9.70 CHF)
                </label>
              </div>
            </div>
            <div className='priceBoxChoice'>
              <h2>Business Package 👔</h2>
              <div className='priceBoxList'>
                <ul>
                  <li>{selectedLanguage.translation.prices.businessPackageFeatures[0]}</li>
                  <li>{selectedLanguage.translation.prices.businessPackageFeatures[1]}</li>
                  <li>{selectedLanguage.translation.prices.businessPackageFeatures[2]}</li>
                  <li>{selectedLanguage.translation.prices.businessPackageFeatures[3]}</li>
                </ul>
              </div>
              <div>
                <label className={`buyButtonChoice ${selectedOption === 'product2' ? 'blackBackground' : ''}`}>
                  <input
                    type="radio"
                    name="product"
                    value="product2"
                    checked={selectedOption === 'product2'}
                    onChange={handleSelectedOption}
                    required
                  />
                  Select (17.90 CHF)
                </label>
              </div>
            </div>
            <div className='priceBox'>
              <h2>Corporate Package 👑</h2>
              <div className='priceBoxList'>
                <ul>
                  <li>{selectedLanguage.translation.prices.corporatePackageFeatures[0]}</li>
                  <li>{selectedLanguage.translation.prices.corporatePackageFeatures[1]}</li>
                  <li>{selectedLanguage.translation.prices.corporatePackageFeatures[2]}</li>
                  <li>{selectedLanguage.translation.prices.corporatePackageFeatures[3]}</li>
                </ul>
              </div>
              <div>
                <label className={`buyButton ${selectedOption === 'product3' ? 'blackBackground' : ''}`}>
                  <input
                    type="radio"
                    name="product"
                    value="product3"
                    checked={selectedOption === 'product3'}
                    onChange={handleSelectedOption}
                    required
                  />
                  Select (39.90 CHF)
                </label>
              </div>
            </div>
          </div>
          {/* Picture Options Box */}
          {/* Render the "Style" dropdown only when product2 or product3 is selected */}
          {(selectedOption === 'product2' || selectedOption === 'product3') && (
            <div className="pictureOptionsContainer">
              <h2 className="pictureOptionsHeading">Picture Options</h2>
              <h3 className="pictureOptionsText">Select the desired style and background for your photoshoot</h3>
              <div className="pictureOptionsRow">
                <label className="pictureOptionsLabel">Style:</label>
                <select
                  value={selectedStyle}
                  onChange={handleSelectedStyle}
                  className="pictureOptionsDropdown"
                >
                  {selectedGender === 'man' && (
                    <>
                      <option value="suit">Suit</option>
                      <option value="dress shirt">Shirt</option>
                      <option value="polo shirt">Polo Shirt</option>
                      <option value="tuxedo">Tuxedo</option>
                    </>
                  )}
                  {selectedGender === 'woman' && (
                    <>
                      <option value="blazer">Blazer</option>
                      <option value="dress shirt">Shirt</option>
                    </>
                  )}
                  {selectedGender === 'person' && (
                    <>
                      <option value="suit">Suit</option>
                      <option value="dress shirt">Shirt</option>
                      <option value="blazer">Blazer</option>
                      <option value="tuxedo">Tuxedo</option>
                    </>
                  )}
                </select>
              </div>
              <div className="pictureOptionsRow">
                <label className="pictureOptionsLabel">Colour of Clothes:</label>
                <select
                  value={selectedColour}
                  onChange={handleSelectedColour}
                  className="pictureOptionsDropdown"
                >
                  <option value="navy blue">Navy Blue</option>
                  <option value="dark grey">Dark Grey</option>
                  <option value="mint green">Mint Green</option>
                  <option value="pink">Pink</option>
                  <option value="black">Black</option>
                </select>
              </div>
              <div className="pictureOptionsRow">
                <label className="pictureOptionsLabel">Background:</label>
                <select
                  value={selectedBackground}
                  onChange={handleSelectedBackground}
                  className="pictureOptionsDropdown"
                >
                  <option value="grey background">Neutral Background</option>
                  <option value="Corporate/Office background">Corporate/Office Background</option>
                  <option value="Nature background">Nature Background</option>
                </select>
              </div>
            </div>
          )}
          {/*<button onClick={toggleModal}>Show Modal</button>
          <Modal onClose={toggleModal} show={showModal} selectedLanguage={selectedLanguage}>
            <ImageCheckContent selectedLanguage={selectedLanguage} />       

          </Modal > */}
          <button type='submit' className='submitButton' disabled={isLoading}>
            Buy & Upload
          </button>
          {error && <div className='errorMessage'>{error}</div>}


          <div className='image-container'>
            <img src={require('./media/testemonials.png')} alt='Testemonials' />
          </div>
        </form>
        {isLoading && (
          <div className='overlay'>
            {/* Overlay to prevent clicks */}
          </div>
        )}
        {isLoading && (
          <div className='spinnerContainer'>
            <div className='spinner'></div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default UploadForm;
